<script setup lang="ts">
import { BlockConfigDetailsFragment, RunStatus } from '@/generated/sdk'
import { useRunResults } from '@/runs'
import { CheckMark, DisplayDate, ShowMoreButton, TwinIcon } from '@/ui/components'
import { useUtils } from '@/ui/composables'
import { type BlockOutput } from '@/workflow-edit/sidebar/useBlockOutput'
import { Chip, Column, Row, Spinner } from '@madxnl/dodo-ui'
import { computed, watch } from 'vue'
import EditorDataCell from './EditorDataCell.vue'
import { useDataTable } from './useReviewDataTable'

const { selectedRunIds, toggleRun } = useRunResults()
const { camelCaseToReadable } = useUtils()

const props = defineProps<{
  blockConfig: BlockConfigDetailsFragment
  blockOutput: BlockOutput
  inputArguments?: { name: string }[]
}>()

const { tableRuns, allHeaders, rows } = useDataTable(props.inputArguments)

const runs = computed(() => props.blockOutput.runs.value)
const parentWorkflow = computed(() => props.blockOutput.parentWorkflow.value)

watch(runs, () => (tableRuns.value = runs.value), { immediate: true })

const failedReason = (i: number) => runs.value?.[i]?.failedReason
const stacktrace = (i: number) => runs.value?.[i]?.stacktrace
const getColorForStatus = (s: string) =>
  s === 'Done' ? 'success' : s === 'Running' ? 'warning' : s === 'Failed' ? 'danger' : undefined

function clickSelectRun(runId: string) {
  const workflowId = parentWorkflow.value?.id ?? props.blockConfig.workflow?.id
  if (!workflowId) return
  toggleRun(workflowId, runId)
}
</script>

<template>
  <template v-if="runs">
    <Row v-if="runs.length === 0" :class="$style.colorDisabled" padding="0">
      <TwinIcon icon="Info" size="s" />
      <small>No runs executed by the workflow itself</small>
    </Row>

    <div v-else>
      <div :class="$style.EditorDataTable">
        <table>
          <thead>
            <tr>
              <th v-for="header in allHeaders" :key="header" :class="[$style.colname]">
                <Row v-if="header !== 'Run Id'">
                  <h4>{{ header }}</h4>
                </Row>
              </th>
            </tr>
          </thead>

          <tbody>
            <template v-for="(row, index) in rows" :key="row.id">
              <tr>
                <td v-for="(value, key) in row" :key="key" :class="$style.td">
                  <template v-if="key === 'runId' && typeof value === 'string'">
                    <CheckMark
                      :title="value"
                      :checked="selectedRunIds.includes(value)"
                      @click="clickSelectRun(value)"
                    />
                  </template>

                  <template v-else-if="key === 'run' && typeof value === 'string'">
                    <Column gap="0">
                      Unnamed run
                      <small :class="$style.colorDisabled">
                        <DisplayDate :date="parseInt(value)" />
                      </small>
                    </Column>
                  </template>

                  <template v-else-if="key === 'status' && typeof value === 'string'">
                    <EditorDataCell :value="value" name="Status" modal-title="Failed reason">
                      <template #default="{ openModal }">
                        <Row style="min-width: 96px">
                          <Chip :color="getColorForStatus(value)">{{ value }}</Chip>
                          <TwinIcon
                            v-if="value === RunStatus.Failed"
                            icon="Question"
                            :class="$style.colorDisabled"
                            style="cursor: pointer"
                            @click="openModal"
                          />
                        </Row>
                      </template>

                      <template v-if="row.status === RunStatus.Failed" #modalContent>
                        <code>{{ failedReason(index) }}</code>
                        <small>
                          <code>{{ stacktrace(index) }}</code>
                        </small>
                      </template>
                    </EditorDataCell>
                  </template>

                  <template v-else>
                    <EditorDataCell :value="value" :name="key" :modal-title="camelCaseToReadable(key)" />
                  </template>
                </td>

                <td :class="$style.filler" />
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <Row v-if="blockOutput.showMore.value" justify="center">
        <ShowMoreButton :show-more="blockOutput.showMore.value" variant="clear" />
      </Row>
    </div>
  </template>

  <Spinner v-else />
</template>

<style module>
.EditorDataTable {
  position: relative;
  overflow-x: auto;
}

.EditorDataTable table {
  width: 100%;
  white-space: nowrap;
}

.EditorDataTable th {
  padding-left: 8px;
  padding-right: 8px;
  padding: 0 8px 16px;
}

.EditorDataTable th:first-of-type,
.EditorDataTable .td:first-of-type {
  padding-left: 0;
}

.outputHeader {
  border-bottom: 2px solid var(--grey-3-outlines);
  background-color: var(--grey-2-lines);
  padding: 0 4px;
}

.td {
  padding: 8px;
}

.filler {
  padding: 0;
  width: 100%;
}

.colname {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 8px;
}

.pendingRun {
  height: 90px;
  max-width: 100%;
}

.pendingRun div {
  position: absolute;
  display: flex;
  left: 0px;
  right: 0px;
  z-index: 31;
  text-align: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 90px;
  border-top: 2px solid #eee;
}

.listTitle,
.listOption {
  border-bottom: 1px solid var(--grey-2-lines);
  padding: 8px 0;
}

.listOptionEmpty {
  padding: 8px 0;
}

.colorDisabled {
  color: var(--grey-4-disabled);
}
</style>
