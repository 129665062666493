<script setup lang="ts">
import { WorkflowBlockItemFragment, WorkflowBlockType } from '@/generated/sdk'
import { FormItem, Select, Spinner } from '@madxnl/dodo-ui'
import { computed } from 'vue'
import { useBlockTypes, useWorkflowDetails, useWorkflowEditor } from '../composables'

const props = defineProps<{
  workflowBlock: WorkflowBlockItemFragment
}>()

const { workflow, triggerSaveWorkflow } = useWorkflowDetails()
const { getNextBlocks } = useWorkflowEditor()
const { blockTypes } = useBlockTypes()

const hasDeprecatedCombination = computed(() => {
  // If the current combination is not in the list because it was set in previous version, show it as deprecated
  const { blockType, blockConfig } = props.workflowBlock
  const block = blockTypes.value?.find((b) => b.id === blockConfig?.block)
  const incorrectBlockType = block?.workflowBlockType !== blockType
  return incorrectBlockType
})

const options = computed(() => {
  // Normal blocks, then add if/else and switch as options
  const list = blockTypes.value?.map((b) => ({ label: b.readableName, value: b.id })) ?? []

  // If the current combination is not in the list because it was set in previous version, show it as deprecated
  const { blockType, blockConfig } = props.workflowBlock
  if (hasDeprecatedCombination.value) {
    const block = blockTypes.value?.find((b) => b.id === blockConfig?.block)
    list.push({ label: `${blockType} + ${block?.readableName ?? blockConfig.block} (deprecated)`, value: 'deprecated' })
  }

  // Sort options by name
  list.sort((a, b) => a.label.localeCompare(b.label))
  return list
})

const getCurrentOptionId = computed(() => {
  // Determine the current option id based on the block type and block config
  if (hasDeprecatedCombination.value) return 'deprecated'
  const { blockConfig } = props.workflowBlock
  return blockConfig.block
})

const getCurrentOptionDescription = computed(() => {
  if (hasDeprecatedCombination.value) return 'deprecated'
  const { blockConfig } = props.workflowBlock
  const block = blockTypes.value?.find((b) => b.id === blockConfig?.block)
  return block?.description ?? ''
})

function setCurrentOption(optionId: string | null | undefined) {
  if (!optionId) return
  // Set both the block id and blockType based on the selected option
  if (optionId === 'deprecated') return
  const option = blockTypes.value?.find((b) => b.id === optionId)
  if (!option) return
  props.workflowBlock.blockConfig.block = option.id
  props.workflowBlock.blockType = option.workflowBlockType
  fixConnectionConditions()
  triggerSaveWorkflow()
}

function fixConnectionConditions() {
  // Assign the condition field when changing the block type
  if (props.workflowBlock == null) return
  const blockType = props.workflowBlock.blockType
  const nextBlocks = getNextBlocks(props.workflowBlock!)
  nextBlocks?.forEach((block, i) => {
    if (!block) return
    if (blockType === WorkflowBlockType.IfElse) {
      if (!['true', 'false'].includes(block.condition!)) {
        block.condition = i < nextBlocks.length - 1 ? 'true' : 'false'
      }
    } else if (blockType === WorkflowBlockType.Switch) {
      if (block.condition == null) {
        block.condition = 'ab'[i] || '?'
      }
    } else {
      // normal
      block.condition = null
    }
  })
}
</script>

<template>
  <FormItem label="Block">
    <Select
      v-if="blockTypes"
      :model-value="getCurrentOptionId"
      :disabled="!workflow?.draft"
      :options="options"
      @update:model-value="setCurrentOption"
    />
    <Spinner v-else />
  </FormItem>

  <p class="form-description">{{ getCurrentOptionDescription }}</p>
</template>
