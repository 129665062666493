import { RunItemFragment } from '@/generated/sdk'
import { useUtils } from '@/ui/composables'
import { computed, ref } from 'vue'

const { camelCaseToReadable, firstCharToUpperCase } = useUtils()

export function useDataTable(inputArguments?: { name: string }[]) {
  const tableRuns = ref<RunItemFragment[] | undefined>()

  const outputHeaders = computed(() => {
    if (!tableRuns.value) return null
    return getHeaders(tableRuns.value.map((run) => run.output))
  })

  const inputHeaders = computed(() => {
    if (!tableRuns.value) return null
    if (inputArguments) {
      return inputArguments.map((arg) => arg.name)
    }
    return getHeaders(tableRuns.value?.map((run) => run.input))
  })

  const rows = computed(() =>
    tableRuns.value?.map((run) => {
      const output = getValues(run.output, outputHeaders.value, 'output')
      const input = getValues(run.input, inputHeaders.value, 'input')

      const data: Record<string, unknown> = {
        runId: run.id,
        run: run.createdAt,
        status: run.status,
        ...output,
        ...input,
      }
      return data
    }),
  )

  const allHeaders = computed(() => {
    const firstValue = rows.value?.find((obj) => obj)
    if (!firstValue) return []
    const data = firstValue as Record<string, unknown>
    if (data._modelName === 'file') {
      return ['File']
    }
    return Object.keys(firstValue).map(camelCaseToReadable)
  })

  return { tableRuns, allHeaders, inputHeaders, outputHeaders, rows }
}

function getValues(obj: unknown, headers: string[] | null, type: 'input' | 'output') {
  // No headers, so we only have an input/output column
  if (!headers) return { [type]: obj }

  if (obj && typeof obj === 'object' && '_modelName' in obj && obj._modelName === 'file') {
    return { File: obj }
  }

  const record: Record<string, unknown> = {}
  if (obj && typeof obj === 'object') Object.assign(record, obj)

  return headers.reduce(
    (acc, header) => {
      acc[`${firstCharToUpperCase(header)}`] = header in record ? record[header] : null
      return acc
    },
    {} as Record<string, unknown>,
  )
}

function getHeaders(objects: unknown[]) {
  const firstValue = objects.find((obj) => obj)

  // We do not convert arrays or primitives
  if (!firstValue || Array.isArray(firstValue) || typeof firstValue !== 'object') {
    return null
  }

  return [...new Set(objects.flatMap((obj) => (obj != null && typeof obj === 'object' ? Object.keys(obj) : [])))]
}
