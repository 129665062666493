import { useApiClient } from '@/api'
import { BlockArgumentInputType, WorkflowInputArgumentFragment, BlockArgumentType } from '@/generated/sdk'
import { useValidation } from '@madxnl/dodo-ui'
import { reactive, ref } from 'vue'
import { useWorkflowSettings } from './useWorkflowSettings'

const name = ref('')
const description = ref('')
const inputType = ref<BlockArgumentInputType>()
const argumentType = ref<BlockArgumentType>()
const data = reactive({ name, description, inputType, argumentType })
const argumentId = ref('')

export function useWorkflowSettingForm() {
  const { client } = useApiClient()
  const { workflowInputArguments } = useWorkflowSettings()

  function nameValidator(name?: string) {
    const nameTaken = !!workflowInputArguments?.value.some((a) => a.name === data.name && a.id !== argumentId.value)
    if (nameTaken) return 'The title must be unique.'
    return undefined
  }

  const { errors, validate } = useValidation({
    name: { value: name, required: true, maxLen: 50, validators: [nameValidator] },
    description: { value: description },
    inputType: { value: inputType, required: true },
    argumentType: { value: argumentType, required: true },
  })

  function reset() {
    name.value = ''
    description.value = ''
    inputType.value = undefined
    argumentType.value = undefined
    argumentId.value = ''
  }

  function load(setting: WorkflowInputArgumentFragment) {
    argumentId.value = setting.id
    name.value = setting.name
    inputType.value = setting.inputType
    argumentType.value = setting.argumentType
    description.value = setting.description
  }

  async function submit() {
    const isValid = await validate()
    if (!isValid) return false
    await client.updateWorkflowInputArgument({ input: { id: argumentId.value, ...data } })
    return true
  }

  return { data, load, submit, errors, reset }
}
