import { useBlockOutput, useWorkflowDetails } from '@/workflow-edit'
import { computed, watch } from 'vue'

export function useWorkflowData() {
  const { workflow } = useWorkflowDetails()

  const workflowData = computed(() => {
    return workflow.value?.blockConfigs.map((blockConfig) => {
      const blockOutput = useBlockOutput()
      return { blockConfig, blockOutput }
    })
  })

  watch(workflow, fetch, { immediate: true })

  async function fetch() {
    if (!workflow.value || !workflowData.value) return

    const promises = workflowData.value.map(({ blockOutput, blockConfig }) => {
      return blockOutput.fetchOutput(blockConfig.id)
    })
    await Promise.all(promises)
  }

  function isDataFromParentWorkflow(blockConfigId: string) {
    const parentWorkflowId = workflowData.value?.find(({ blockConfig }) => blockConfig.id === blockConfigId)
      ?.blockOutput.parentWorkflow.value?.id
    if (parentWorkflowId == null) return false
    return parentWorkflowId !== workflow.value?.id
  }

  function dataName(blockConfigId: string) {
    const blockOutput = workflowData.value?.find(({ blockConfig }) => blockConfig.id === blockConfigId)?.blockOutput
    if (!blockOutput) return 'Unknown'
    const parentWorkflowName = blockOutput.parentWorkflow.value?.name
    if (!parentWorkflowName) return workflow.value?.name || 'Unknown'
    const workflowBlockName = blockOutput.workflowBlock.value?.name
    if (!workflowBlockName) return parentWorkflowName
    return `${parentWorkflowName} - ${workflowBlockName}`
  }

  return {
    workflowData,
    isDataFromParentWorkflow,
    dataName,
  }
}
