<script setup lang="ts">
import { WorkflowInputArgumentFragment } from '@/generated/sdk'
import { LargeModalLayout, TwinIcon } from '@/ui/components'
import { useSimpleMessage } from '@/ui/composables'
import { Button, Column, Form, FormItem, Row, Select, TextInput } from '@madxnl/dodo-ui'
import { ref, watchEffect } from 'vue'
import { useWorkflowSettingForm } from './useWorkflowSettingForm'
import { argumentTypes, inputTypes, useWorkflowSettings } from './useWorkflowSettings'

const { updateArgument, deleteArgument, refreshWorkflow } = useWorkflowSettings()
const { data, load, reset, errors, submit: submitForm } = useWorkflowSettingForm()
const { showMessage } = useSimpleMessage()

const props = defineProps<{
  setting: WorkflowInputArgumentFragment
  disabled: boolean
}>()

const isEditing = ref(false)

watchEffect(() => (isEditing.value ? load(props.setting) : reset()))

async function clickSubmit() {
  const result = await submitForm()
  if (!result) return
  isEditing.value = false
  await refreshWorkflow()
  showMessage('Input data field updated')
}
</script>

<template>
  <Column gap="s">
    <Row justify="between">
      <h5>{{ setting.name }}</h5>
      <Row>
        <Button color="secondary" square size="s" variant="link" :disabled="disabled" @click="isEditing = true">
          <TwinIcon size="s" icon="Edit" />
        </Button>
        <Button
          color="secondary"
          square
          size="s"
          variant="link"
          :disabled="disabled"
          @click="deleteArgument(setting.id)"
        >
          <TwinIcon size="s" icon="Delete" />
        </Button>
      </Row>
    </Row>

    <FormItem :description="setting.description">
      <Select
        v-model="setting.argumentType"
        :options="argumentTypes"
        :disabled="disabled"
        @update:model-value="() => updateArgument(setting)"
      />
    </FormItem>
  </Column>

  <LargeModalLayout :open="isEditing" title="Edit input data field" @close="isEditing = false">
    <template #content>
      <Form @submit="clickSubmit">
        <FormItem label="Name" :error="errors.name">
          <TextInput v-model="data.name" />
        </FormItem>
        <FormItem label="Description" :error="errors.description">
          <TextInput v-model="data.description" />
        </FormItem>
        <FormItem label="Argument type" :error="errors.argumentType">
          <Select v-model="data.argumentType" :options="argumentTypes" />
        </FormItem>
        <FormItem label="Input type" :error="errors.inputType">
          <Select v-model="data.inputType" :options="inputTypes" />
        </FormItem>
      </Form>
    </template>
    <template #footer="{ close }">
      <Button @click="close()">Cancel</Button>
      <Button variant="solid" color="primary" type="submit" @click.prevent="clickSubmit">Save</Button>
    </template>
  </LargeModalLayout>
</template>
