<script setup lang="ts">
import { BlockConfigArgumentType, BlockConfigDetailsFragment, WorkflowBlockItemFragment } from '@/generated/sdk'
import { TwinIcon } from '@/ui/components'
import { Button, Form, FormItem, Row, TextInput } from '@madxnl/dodo-ui'
import { computed, reactive, toRefs, watchEffect } from 'vue'
import { BlockSettingsField } from '.'
import { useWorkflowDetails } from '../composables'
import { useBlockSettingsContext } from './useBlockSettingsContext'
import { useFieldGeneration } from './useFieldGeneration'
import { useManageArguments } from './useManageArguments'

const { workflow } = useWorkflowDetails()
const { setContext } = useBlockSettingsContext()
const { generatedFields } = useFieldGeneration()
const { addExtraArgument, canAddExtraArgument, isNameTaken } = useManageArguments()

const props = defineProps<{
  selectedBlock?: WorkflowBlockItemFragment
  blockConfig?: BlockConfigDetailsFragment
  context: 'input' | 'output' | 'settings'
}>()

const { blockConfig } = toRefs(props)

const disabled = computed(() => !workflow.value?.draft)

watchEffect(() => setContext(workflow.value, props.context, blockConfig.value))

const data = reactive({
  name: '',
})

async function clickAddArgument() {
  const argumentType = props.context === 'input' ? BlockConfigArgumentType.Reference : BlockConfigArgumentType.Constant
  await addExtraArgument(data.name, argumentType)
  data.name = ''
}
</script>

<template>
  <p v-if="!generatedFields.length" class="form-description">
    <template v-if="context === 'output'">
      Add the expected output data generated by the workflow. After running the workflow, this output will be shown in
      the results of the workflow.
    </template>
    <template v-else>No {{ context }} items yet</template>
  </p>

  <template v-else>
    <BlockSettingsField
      v-for="field of generatedFields"
      :key="String(field.namePath)"
      :disabled="disabled"
      :field="field"
    />
  </template>

  <Form v-if="!disabled && canAddExtraArgument" @submit="clickAddArgument">
    <hr />
    <h5>
      <template v-if="context === 'output'">Add expected output data</template>
      <template v-else> Add a new {{ context }} item </template>
    </h5>
    <FormItem>
      <Row>
        <TextInput v-model="data.name" style="flex: 1" type="text" autocomplete="off" placeholder="Enter a name" />
        <Button :disabled="data.name === '' || !isNameTaken" type="submit" square>
          <TwinIcon icon="Plus" />
        </Button>
      </Row>
    </FormItem>
  </Form>
</template>
