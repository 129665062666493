import { useApiClient } from '@/api'
import { useCurrentUser } from '@/auth/composables'
import { RunInputDataItemFragment, WorkflowInputArgumentFragment } from '@/generated/sdk'
import { useValidation } from '@madxnl/dodo-ui'
import { computed, reactive, ref } from 'vue'

export function useWorkflowRunAndInputData() {
  const { client } = useApiClient()
  const { currentUser } = useCurrentUser()

  const data = reactive({
    createNewInputData: false,
    name: '',
    text: '',
    selectedInputDataId: '',
  })

  const { validate, errors } = useValidation({
    name: { value: computed(() => data.name), validators: [nameValidator] },
    text: { value: computed(() => data.text), validators: [textValidator] },
  })

  const workflowId = ref('')
  const availableInputDatas = ref<RunInputDataItemFragment[]>()
  const workflowInputArgs = ref<WorkflowInputArgumentFragment[]>()

  const json = computed(() => tryParseJson(data.text))
  const jsonDict = computed(() => (isRecord(json.value) ? json.value : {}))

  const inputNames = computed(() => {
    const fromWorkflow = workflowInputArgs.value?.map((arg) => arg.name) ?? []
    const fromData = Object.keys(jsonDict.value)
    const allArgs = [...fromWorkflow, ...fromData]
    const uniqueArgs = [...new Set(allArgs)]
    return uniqueArgs.sort((a, b) => a.localeCompare(b))
  })

  async function initForm(args: { workflowId: string }) {
    clear()
    workflowId.value = args.workflowId
    await Promise.all([fetchInputDatas(), fetchWorkflowArgs()])
  }

  async function fetchInputDatas() {
    const input = { workflow: { id: workflowId.value } }
    const response = await client.runInputData({ input })
    availableInputDatas.value = response.runInputData
  }

  async function fetchWorkflowArgs() {
    const response = await client.workflowInputArguments({ id: workflowId.value })
    workflowInputArgs.value = response.workflow[0]?.inputArguments
  }

  function loadInputData(inputData: RunInputDataItemFragment) {
    data.name = inputData.name
    data.text = JSON.stringify(inputData.data, null, 2)
  }

  async function submitRun() {
    const valid = await validate()
    if (!valid) return null
    if (data.createNewInputData) await submitInputData()
    const result = await client.runByWorkflowId({ workflowId: workflowId.value, data: json.value })
    clear()
    return result.runByWorkflowId.id
  }

  async function submitInputData() {
    const organization = { id: currentUser.value!.organization.id }
    const workflow = { id: workflowId.value }
    const input = { name: data.name, data: json.value, organization, workflow }
    await client.createRunInputData({ input })
  }

  function clear() {
    workflowId.value = ''
    data.name = ''
    data.text = ''
    data.createNewInputData = false
  }

  // Utility functions
  function isRecord(obj: unknown): obj is Record<string, unknown> {
    return !!obj && typeof obj === 'object' && !Array.isArray(obj)
  }

  function tryParseJson(value: string) {
    try {
      return JSON.parse(value) as unknown
    } catch (e) {
      return value
    }
  }

  function getArgValue(argName: string) {
    const value = jsonDict.value[argName]
    if (!value) return ''
    if (typeof value === 'string') return value
    return JSON.stringify(value, null, 2)
  }

  function setArgValue(argName: string, valueText: string) {
    const value = tryParseJson(valueText)
    const newDict = Object.assign({}, jsonDict.value, { [argName]: value })
    data.text = JSON.stringify(newDict, null, 2)
  }

  function getArgError(argName: string) {
    if (!jsonDict.value[argName] && errors.text) return 'Missing value'
    return undefined
  }

  function textValidator() {
    const missingValues = inputNames.value.filter((name) => !jsonDict.value[name])
    if (missingValues.length) return `Missing values for: ${missingValues.join(', ')}`
    return undefined
  }

  function nameValidator(value: string) {
    if (!data.createNewInputData) return undefined
    if (data.createNewInputData && !value) return 'Name is required'
    if (availableInputDatas.value?.find((input) => input.name === value)) return 'Name already exists'
    return undefined
  }

  return {
    data,
    errors,
    availableInputDatas,
    inputNames,
    initForm,
    loadInputData,
    submitRun,
    getArgValue,
    setArgValue,
    getArgError,
    clear,
  }
}
