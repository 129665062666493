<script setup lang="ts">
import { TwinIcon } from '@/ui/components'
import { useWorkflowDetails } from '@/workflow-edit/composables'
import { Button, Column, Form, FormItem, Row, TextInput } from '@madxnl/dodo-ui'
import { computed, reactive } from 'vue'
import { useWorkflowSettings } from './useWorkflowSettings'
import WorkflowSetting from './WorkflowSetting.vue'

const { workflow } = useWorkflowDetails()
const { createArgument, workflowInputArguments } = useWorkflowSettings()

const disabled = computed(() => !workflow.value?.draft)
const isNameTaken = computed(() => !!workflowInputArguments?.value.find((a) => a.name === data.name))

const data = reactive({
  name: '',
})

async function clickAddSetting() {
  await createArgument(data.name)
  data.name = ''
}
</script>

<template>
  <p v-if="!workflowInputArguments.length" class="form-description">
    Add the expected input data to be used by the blocks in the workflow. When a new Run is started, the user is asked
    to submit the input data.
  </p>

  <Column v-else gap="l">
    <WorkflowSetting
      v-for="setting of workflowInputArguments"
      :key="setting.id"
      :setting="setting"
      :disabled="disabled"
    />
  </Column>

  <Form v-if="!disabled" @submit="clickAddSetting">
    <hr />
    <h5>Add expected input data</h5>
    <FormItem>
      <Row>
        <TextInput v-model="data.name" style="flex: 1" type="text" autocomplete="off" placeholder="Enter a name" />
        <Button :disabled="data.name === '' || isNameTaken" type="submit" square @click.prevent="clickAddSetting">
          <TwinIcon icon="Plus" />
        </Button>
      </Row>
    </FormItem>
  </Form>
</template>
