<script lang="ts" setup>
import { CheckMark, LargeModalLayout, TwinIcon } from '@/ui/components'
import { useSimpleMessage } from '@/ui/composables'
import { workflowLinks } from '@/workflows/routes'
import { Button, FormItem, Row, Textarea, TextInput } from '@madxnl/dodo-ui'
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import ChooseInputData from './ChooseInputData.vue'
import { useWorkflowRunAndInputData } from './useWorkflowRunAndInputData'

const router = useRouter()
const { showMessage } = useSimpleMessage()
const {
  data,
  inputNames,
  availableInputDatas,
  errors,
  initForm,
  submitRun,
  loadInputData,
  getArgError,
  getArgValue,
  setArgValue,
  clear,
} = useWorkflowRunAndInputData()

const props = defineProps<{
  workflowId: null | string
  open: boolean
}>()

const emits = defineEmits<{
  close: []
}>()

watch(() => props.open, onOpen, { immediate: true })

async function onOpen() {
  if (!props.open || !props.workflowId) return
  await initForm({ workflowId: props.workflowId })
}

async function handleRunWorkflow() {
  const response = await submitRun()
  if (!response) return // Validation failed
  showMessage('Running workflow')
  await router.push(workflowLinks.workflowData(props.workflowId!))
}

function selectInputData(id: string) {
  const input = availableInputDatas.value?.find((input) => input.id === id)
  if (!input) return
  data.selectedInputDataId = id
  loadInputData(input)
}

function clearAndClose() {
  clear()
  emits('close')
}

function toggleSaveData() {
  data.createNewInputData = !data.createNewInputData
}
</script>

<template>
  <LargeModalLayout :open="open" title="Run workflow" @close="clearAndClose">
    <template #content>
      <FormItem label="Previous input data" optional>
        <ChooseInputData :value="data.selectedInputDataId" :options="availableInputDatas" @select="selectInputData" />
      </FormItem>

      <hr />

      <p v-if="inputNames.length === 0">
        No workflow input arguments detected. Please add them in the editor before proceeding.
      </p>
      <template v-for="argName of inputNames" :key="argName">
        <FormItem :label="argName" :error="getArgError(argName)">
          <Textarea
            placeholder="Enter a value"
            :model-value="getArgValue(argName)"
            @update:model-value="(value) => setArgValue(argName, value ?? '')"
          />
        </FormItem>
      </template>

      <FormItem @click="toggleSaveData">
        <Row>
          <CheckMark :checked="data.createNewInputData">Save this data</CheckMark>
        </Row>
      </FormItem>

      <FormItem v-show="data.createNewInputData" label="Input data name" :error="errors.name">
        <TextInput v-model="data.name" placeholder="Name your input data" />
      </FormItem>
    </template>

    <template #footer="{ close }">
      <Row>
        <Button @click="close"> Cancel </Button>
        <Button color="primary" variant="solid" @click="handleRunWorkflow">
          <TwinIcon icon="Play" />
          Run now
        </Button>
      </Row>
    </template>
  </LargeModalLayout>
</template>
