import { useApiClient } from '@/api'
import { WorkflowBlockItemFragment } from '@/generated/sdk'
import { ref } from 'vue'

export function useWorkflowBlock() {
  const workflowBlock = ref<WorkflowBlockItemFragment>()
  const { client } = useApiClient()

  async function fetch(id: string) {
    const res = await client.getWorkflowBlock({ id })
    workflowBlock.value = res.workflowBlock[0]
  }

  return { workflowBlock, fetch }
}
