<script setup lang="ts">
import { TabularExportType } from '@/generated/sdk'
import { MainPageLayout } from '@/navigation/components'
import { useRunResults } from '@/runs/composables'
import { IconBullet, LargeModalLayout, TwinIcon } from '@/ui/components'
import { useEditorLinks, useWorkflowDetails } from '@/workflow-edit/composables'
import { type BlockOutput } from '@/workflow-edit/sidebar/useBlockOutput'
import { workflowLinks } from '@/workflows/routes'
import { Button, Column, Dropdown, FormItem, Row, Select, TextInput } from '@madxnl/dodo-ui'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import DataActionBar from './DataActionBar.vue'
import EditorDataTable from './EditorDataTable.vue'
import { useWorkflowData } from './useWorkflowData'

const router = useRouter()
const { linkReviewData } = useEditorLinks()

const { workflow } = useWorkflowDetails()
const { workflowData, isDataFromParentWorkflow, dataName } = useWorkflowData()
const { resetSelectedRuns, downloadResultsLatestRun, downloadResultsAllRuns } = useRunResults()

watch(workflow, resetSelectedRuns, { immediate: true })
watch(workflowData, resetSelectedRuns, { immediate: true })

const showFilters = ref(false)
const selectedBlockConfigId = ref<string | undefined>(undefined)

const filterOptions = computed(() => [
  { label: 'All outputs', value: undefined },
  ...(workflowData.value?.map(({ blockConfig }) => ({
    label: dataName(blockConfig.id),
    value: blockConfig.id,
  })) || []),
])

async function clickDownloadResultsLatestRun(type: TabularExportType) {
  const blockConfigId = workflow.value?.blockConfigs[0]!.id || ''
  await downloadResultsLatestRun(blockConfigId, type)
}

function clickClearFilters() {
  selectedBlockConfigId.value = undefined
  showFilters.value = false
}

async function clickOpenParentWorkflow(blockOutput: BlockOutput) {
  const parentWorkflow = blockOutput.parentWorkflow.value
  const workflowBlock = blockOutput.workflowBlock.value
  if (!parentWorkflow) return
  if (workflowBlock) return await router.push(linkReviewData(parentWorkflow.id, workflowBlock.id))
  await router.push(workflowLinks.workflowData(parentWorkflow.id))
}
</script>

<template>
  <MainPageLayout full-screen>
    <template v-if="workflow">
      <Column gap="xl">
        <Column>
          <Row>
            <Row grow>
              <h1>Results of {{ workflow.name }}</h1>
            </Row>
            <Row>
              <Dropdown class="navbar-dropdown">
                <template #default>
                  <Button> Download </Button>
                </template>
                <template #dropdown>
                  <Button
                    variant="clear"
                    :class="$style.dropdownButton"
                    @click="downloadResultsAllRuns(workflow.id, TabularExportType.Excel)"
                  >
                    Download all (.xlsx)
                  </Button>
                  <Button
                    variant="clear"
                    :class="$style.dropdownButton"
                    @click="clickDownloadResultsLatestRun(TabularExportType.Excel)"
                  >
                    Download latest (.xlsx)
                  </Button>
                  <Button
                    variant="clear"
                    :class="$style.dropdownButton"
                    @click="downloadResultsAllRuns(workflow.id, TabularExportType.Csv)"
                  >
                    Download all (.csv)
                  </Button>
                  <Button
                    variant="clear"
                    :class="$style.dropdownButton"
                    @click="clickDownloadResultsLatestRun(TabularExportType.Csv)"
                  >
                    Download latest (.csv)
                  </Button>
                </template>
              </Dropdown>
            </Row>
          </Row>

          <p>
            Here you can find the latest workflow results. Every line is an instance of the workflow, called a run.<br />
            Each run has a result, as shown in the first column of the tabel below. The input used to run the workflow
            is visible in the columns after the result column.
          </p>
        </Column>

        <Column>
          <Row justify="between">
            <h3>Workflow output & input data</h3>
            <Row gap="m">
              <Button disabled>Edit columns</Button>
              <Button @click="showFilters = true">Filters</Button>

              <TextInput placeholder="Search" disabled />
            </Row>
          </Row>

          <template v-for="{ blockConfig, blockOutput } in workflowData" :key="blockConfig.id">
            <template v-if="!selectedBlockConfigId || selectedBlockConfigId === blockConfig.id">
              <template v-if="isDataFromParentWorkflow(blockConfig.id)">
                <hr />
                <Row gap="l">
                  <Row>
                    <IconBullet icon="Workflow" color="orange" />
                    <Button class="button-narrow" variant="link" @click="() => clickOpenParentWorkflow(blockOutput)">
                      <h4>{{ dataName(blockConfig.id) }}</h4>
                      <TwinIcon color="primary" icon="LinkExternal" />
                    </Button>
                  </Row>
                </Row>
              </template>
            </template>

            <EditorDataTable
              v-if="!selectedBlockConfigId || selectedBlockConfigId === blockConfig.id"
              :block-config="blockConfig"
              :block-output="blockOutput"
              :input-arguments="workflow.inputArguments"
            />
          </template>
        </Column>
      </Column>
    </template>

    <template #footer>
      <DataActionBar />
    </template>
  </MainPageLayout>

  <LargeModalLayout :open="showFilters" title="Filter runs" @close="showFilters = false">
    <template #content>
      <Column gap="m">
        <FormItem label="Runs from...">
          <Select v-if="filterOptions" v-model="selectedBlockConfigId" :options="filterOptions" />
        </FormItem>
      </Column>
    </template>
    <template #footer="{ close }">
      <Button @click="clickClearFilters">Clear filters</Button>
      <Button color="primary" variant="solid" @click="close()">Show results</Button>
    </template>
  </LargeModalLayout>
</template>

<style module>
.dropdownButton {
  justify-content: start;
}
</style>
