<script setup lang="ts">
import { TwinIcon } from '@/ui/components'
import SearchableList from '@/ui/components/SearchableList.vue'
import { Button, Card, Row, Select } from '@madxnl/dodo-ui'
import { computed, ref } from 'vue'

const props = defineProps<{
  value?: string
  options?: {
    name: string
    id: string
  }[]
}>()

const emit = defineEmits<{
  select: [reference: string]
}>()

const popover = ref<HTMLElement | null>(null)

const groupedOptions = computed(() => {
  const items =
    props.options?.map((option) => ({
      name: option.name,
      id: option.id,
      currentValue: option.id === props.value,
    })) || []
  return [{ groupName: null, items }]
})

const currentLabel = computed(() => {
  const options = groupedOptions.value.flatMap((group) => group.items)
  const option = options.find((option) => option.id === props.value)
  return option?.name ?? ''
})

function select(option: string) {
  emit('select', option)
  closePopover()
}

const options = computed(() => {
  const label = props.value ? currentLabel.value : ''
  return [{ label, value: props.value ?? null }]
})

function showPopover() {
  popover.value?.showPopover()
}

function closePopover() {
  popover.value?.hidePopover()
}
</script>

<template>
  <Select
    :model-value="value"
    :options="options"
    style="flex-grow: 1"
    @click="showPopover"
    @keydown.space.prevent="showPopover"
    @mousedown.prevent
  />
  <div ref="popover" popover :class="$style.popover">
    <Card padding="0" gap="0" :class="$style.card">
      <Row padding="m">
        <Row grow>
          <h3>Previous input</h3>
        </Row>
        <Button variant="clear" square @click="closePopover">
          <TwinIcon icon="Cross" />
        </Button>
      </Row>
      <SearchableList :grouped-options="groupedOptions" @select="select" />
    </Card>
  </div>
</template>

<style module>
.popover {
  width: 320px;
  box-sizing: border-box;
  border: none;
  background: none;
  overflow: visible;
}
.card {
  box-shadow: var(--card-shadow-hover);
}
</style>
