import { useApiClient } from '@/api'
import { BlockArgumentInputType, BlockArgumentType, WorkflowInputArgumentFragment } from '@/generated/sdk'
import { computed } from 'vue'
import { useWorkflowDetails } from '../composables'

export const argumentTypes = Object.values(BlockArgumentType).map((type) => ({ label: type, value: type }))
export const inputTypes = Object.values(BlockArgumentInputType).map((type) => ({ label: type, value: type }))

export function useWorkflowSettings() {
  const { client } = useApiClient()
  const { workflow, refreshWorkflow } = useWorkflowDetails()

  const workflowInputArguments = computed(
    () => workflow.value?.inputArguments.sort((a, b) => a.name.localeCompare(b.name)) || [],
  )

  async function createArgument(name: string) {
    const input = {
      workflow: { id: workflow.value?.id },
      name,
      description: '',
      argumentType: BlockArgumentType.String,
      inputType: BlockArgumentInputType.Text,
    }
    await client.createWorkflowInputArgument({ input })
    await refreshWorkflow()
  }

  async function updateArgument(argument: WorkflowInputArgumentFragment) {
    await client.updateWorkflowInputArgument({ input: argument })
  }

  async function deleteArgument(id: string) {
    await client.deleteWorkflowInputArgument({ id })
    await refreshWorkflow()
  }

  return { refreshWorkflow, createArgument, updateArgument, deleteArgument, workflowInputArguments }
}
